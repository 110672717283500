import React, { useCallback, useEffect, useState } from "react";
import AIChatbotSettingsTabs from "./AIChatbotSettingsTabs";
import { useParams } from "react-router-dom";
import useAIConfigs from "@/hooks/useAIConfigs";
import LoadingSpinner from "@velaro/velaro-shared/src/components/LoadingSpinner";
import AIChatbotSettingsTrainingTabs from "./AIChatbotSettingsTrainingTabs";
import AIChatbotSettingsTrainingTabWebsite from "./AIChatbotSettingsTrainingTabWebsite";
import AIChatbotSettingsTrainingTabSiteMap from "./AIChatbotSettingsTrainingTabSiteMap";
import AIChatbotSettingsTrainingTabFile from "./AIChatbotSettingsTrainingTabFile";
import useApi from "@/hooks/useApi";
import {
  PrimaryBlueButton,
  PrimaryGrayButton,
  SecondaryGrayButton
} from "@velaro/velaro-shared/src/components/Buttons/Buttons";
import IconFile from "@velaro/velaro-shared/src/svg/stroke-icons/file-04.svg?react";
import IconRefresh from "@velaro/velaro-shared/src/svg/stroke-icons/refresh-cw-05.svg?react";
import SortableTable from "@velaro/velaro-shared/src/components/SortableTable";
import AIChatbotSettingsTrainingTabText from "./AIChatbotSettingsTrainingTabText";
import IconFilter from "@velaro/velaro-shared/src/svg/stroke-icons/filter-lines.svg?react";
import CustomizableSelect from "@velaro/velaro-shared/src/components/inputs/CustomizableSelect";
import { AIConfiguration, AzureIndex } from "../types";
import useToastAlert from "@/hooks/useToastAlert";
import { Severity } from "@velaro/velaro-shared/src/components/ToastAlert";
import Modal from "@velaro/velaro-shared/src/components/Modal";
import { set, unescape } from "lodash";
import AIChatbotSettingsRow from "./AIChatbotSettingsRow";

export default function AIChatbotSettingsTraining() {
  const { id } = useParams();
  const { aiConfigurations, loading, update } = useAIConfigs();
  const [selectedTab, setSelectedTab] = useState("Website");
  const [scrapedUrls, setScrapedUrls] = useState<string[]>([]);
  const [config, setConfig] = useState<AIConfiguration>();
  const [configIndex, setConfigIndex] = React.useState<number>();
  const [searchIndex, setSearchIndex] = React.useState<AzureIndex | undefined>(
    undefined
  );
  const [tempSearchIndex, setTempSearchIndex] = React.useState<AzureIndex>({
    id: 0,
    name: "",
    searchSize: 1,
    siteId: 0,
    type: ""
  });
  const [createIndexModal, setCreateIndexModal] = React.useState(false);
  const [deleteIndexModal, setDeleteIndexModal] = React.useState(false);
  const api = useApi();
  const ToastAlert = useToastAlert();

  const fetchUrls = useCallback(async () => {
    //setLoading(true);
    const json = await api.messaging.get(`AIConfiguration/Urls/${id}`);
    const result = (await json.json()) as string[];
    setScrapedUrls(result);
    const index = await api.messaging.get(`AIConfiguration/GetIndex`);
    const indexResult = (await index.json()) as AzureIndex;
    if (indexResult.id !== undefined) {
      setSearchIndex(indexResult);
    }
    //setLoading(false);
  }, [api.messaging, id]);

  useEffect(() => {
    if (aiConfigurations.length > 0 && id) {
      const configIndex = aiConfigurations.findIndex(
        (config) => config.id === parseInt(id)
      );
      if (configIndex !== null && configIndex !== undefined) {
        setConfig(aiConfigurations[configIndex]);
        setConfigIndex(configIndex);
      }
    }
  }, [aiConfigurations, id]);

  async function CreateIndex(index: AzureIndex) {
    if (index.searchSize > 9 || index.searchSize <= 0) {
      ToastAlert.displayToast(
        Severity.Error,
        "Search size must be between 1 and 9"
      );
      return;
    }
    const response = await api.messaging.post("AIConfiguration/CreateIndex", {
      name: index.name,
      searchSize: index.searchSize
    });
    const result = (await response.json()) as AzureIndex;
    if (response.status !== 200) {
      ToastAlert.displayToast(Severity.Error, response.statusText);
      return;
    }
    setSearchIndex({
      id: result.id,
      name: result.name,
      searchSize: result.searchSize,
      type: result.type,
      siteId: result.siteId
    });
    setCreateIndexModal(false);
  }

  async function DeleteIndex() {
    const response = await api.messaging.delete("AIConfiguration/DeleteIndex", {
      name: "Test"
    });
    const result = await response.json();
    if (response.status !== 200) {
      ToastAlert.displayToast(Severity.Error, result);
      return;
    }
    setSearchIndex(undefined);
    setDeleteIndexModal(false);
    ToastAlert.displayToast(Severity.Success, "Index deleted successfully");
  }

  function clearSearchModal() {
    setTempSearchIndex({
      id: 0,
      name: "",
      searchSize: 1,
      siteId: 0,
      type: ""
    });
    setCreateIndexModal(false);
  }

  const handleVectorSearchMethod = (method: string) => {
    if (!config || configIndex == null || configIndex == undefined) return;
    config.vectorSearchMethod = method;
    update({ ...config }, configIndex);
  };

  useEffect(() => {
    fetchUrls();
  }, [fetchUrls, id]);

  const columns = [
    { label: "Title", accessor: "title" },
    { label: "Type", accessor: "type" },
    { label: "Characters", accessor: "characters" },
    { label: "Status", accessor: "status" },
    { label: "", accessor: "edit" }
  ];

  const vectorSearchMethods = [
    {
      label: "None",
      sublabel: "No vector search method",
      action: () => handleVectorSearchMethod("None")
    },
    {
      label: "Website",
      sublabel: "Search vectors from website",
      action: () => handleVectorSearchMethod("Website")
    },
    {
      label: "File",
      sublabel: "Search vectors from file",
      action: () => handleVectorSearchMethod("File")
    }
  ];

  if (loading || !config) return <LoadingSpinner />;

  return (
    <div className="flex flex-col">
      <AIChatbotSettingsTabs selectedTab="Training" aiConfigId={id!} />
      <div className="flex flex-col w-80 pt-2 pb-4">
        <div className="font-semibold text-sm pb-1 flex flex-row ">
          Vector Search Data Method
        </div>
        <div className="text-sm pb-1 flex flex-row ">
          <CustomizableSelect
            options={vectorSearchMethods}
            defaultOption={
              vectorSearchMethods.filter(
                (x) => x.label == config.vectorSearchMethod
              )[0]
            }
          />
        </div>
      </div>
      <div className="bg-white p-6 border-[1px] rounded-2xl shadow-sm w-full self-center">
        <div>
          <div className="font-semibold text-lg pb-1">Data Source</div>
          <div className="text-slate-500 text-sm pb-2">
            Supply information for the chatbot to train on and improve its
            responses.
          </div>
        </div>
        <AIChatbotSettingsTrainingTabs setSelectedTab={setSelectedTab} />
        {selectedTab === "Website" && (
          <AIChatbotSettingsTrainingTabWebsite aiConfig={config} />
        )}
        {selectedTab === "Site Map" && <AIChatbotSettingsTrainingTabSiteMap />}
        {selectedTab === "File" && <AIChatbotSettingsTrainingTabFile />}
        {selectedTab === "Text" && <AIChatbotSettingsTrainingTabText />}
      </div>
      {searchIndex != undefined && (
        <div className="bg-white p-6 border-[1px] rounded-2xl shadow-sm w-full self-center mt-4">
          <div className="flex flex-row">
            <div className="flex flex-col w-1/2">
              <div className="font-semibold text-lg pb-1">Index</div>
              <div className="text-slate-500 text-sm pb-2">
                List of all data sources of your AI chatbot.
              </div>
              <div>Index Name: {searchIndex.name}</div>
              <div>Search Size: {searchIndex.searchSize}</div>
              <div>Index Type: {searchIndex.type}</div>
              <div className="mt-4">
                <PrimaryBlueButton
                  label="Edit Index"
                  onClick={() => {
                    console.log("edit clicked");
                  }}
                />
              </div>
            </div>
            <div className="flex w-2/3 bottom-0 justify-end items-end">
              <PrimaryBlueButton
                className="flex items-end border-red-400 bg-red-400 text-white hover:bg-red-300 hover:border-red-300"
                label="Delete Index"
                onClick={() => {
                  setDeleteIndexModal(true);
                }}
              />
            </div>
          </div>
        </div>
      )}
      {searchIndex == undefined && (
        <div className="bg-white p-6 border-[1px] rounded-2xl shadow-sm w-full self-center mt-4">
          <div></div>
          <PrimaryBlueButton
            label="Create Index"
            onClick={() => setCreateIndexModal(true)}
          />
        </div>
      )}
      {deleteIndexModal && (
        <Modal maxWidth="max-w-3xl" show={true}>
          <div className="bg-white p-4 rounded-2xl w-full self-center mt-4">
            <div className="font-semibold text-lg pb-1">Delete Index</div>
            <div className="text-slate-500 text-sm pb-2">
              Are you sure you want to delete the index?
            </div>
            <div className="flex justify-end mt-2 gap-6">
              <PrimaryGrayButton
                label="Cancel"
                onClick={() => setDeleteIndexModal(false)}
              />
              <PrimaryBlueButton label="Delete" onClick={() => DeleteIndex()} />
            </div>
          </div>
        </Modal>
      )}
      {createIndexModal && tempSearchIndex && (
        <Modal maxWidth="max-w-2xl" show={true}>
          <div className="bg-white p-4 rounded-2xl w-full self-center mt-2">
            <div className="font-semibold text-lg pb-1">Create Index</div>
            <div className="">
              <AIChatbotSettingsRow
                title="Index Name"
                subtitle="A unique name for the index"
                input={
                  <input
                    value={tempSearchIndex.name || ""}
                    onChange={(e) => {
                      setTempSearchIndex({
                        ...tempSearchIndex!,
                        name: e.target.value
                      });
                    }}
                    type="text"
                    placeholder={""}
                    className="w-full p-2 border-[1px] rounded-md text-sm"
                  />
                }
              />
              <AIChatbotSettingsRow
                title="Search Size"
                subtitle="Number of search results to return"
                input={
                  <input
                    value={tempSearchIndex.searchSize}
                    onChange={(e) => {
                      setTempSearchIndex({
                        ...tempSearchIndex!,
                        searchSize: e.target.valueAsNumber
                      });
                    }}
                    onKeyDown={(e) => {
                      if (e.key) {
                        e.preventDefault();
                        return;
                      }
                    }}
                    type="number"
                    min="1"
                    max="9"
                    placeholder={""}
                    className="w-full p-2 border-[1px] rounded-md text-sm"
                  />
                }
              />
            </div>
            <div className="flex justify-end mt-6 gap-6">
              <PrimaryGrayButton
                label="Cancel"
                onClick={() => clearSearchModal()}
              />
              <PrimaryBlueButton
                label="Create"
                onClick={() => CreateIndex(tempSearchIndex)}
              />
            </div>
          </div>
        </Modal>
      )}
      {/* <div className="bg-white p-6 border-[1px] rounded-2xl shadow-sm w-full self-center mt-4">
        <div className="">
          <div className="font-semibold text-lg pb-1">Trained Data</div>
          <div className="flex">
            <div className="text-slate-500 text-sm pb-2">
              List of all data sources of your AI chatbot.
            </div>
            <div className=" text-sm pb-1 flex ml-auto ">
              <span className="pr-2 text-slate-500 ">Data Used:</span>
              <span className="font-semibold"> 0/50</span>
              <span className="pl-2 text-cornflower-blue-400 underline font-semibold">
                Upgrade Plan
              </span>
            </div>
          </div>
        </div>
        <div className="flex items-center py-3">
          <span className="flex pr-2">
            <SecondaryGrayButton
              label="Filter"
              onClick={() => {
                console.log("filter clicked");
              }}
              prefixIcon={<IconFilter className="w-4 h-4" />}
            />
          </span>
          <span>
            <SecondaryGrayButton
              label="Uploaded Files"
              onClick={() => {
                console.log("upload clicked");
              }}
              prefixIcon={<IconFile className="w-4 h-4" />}
            />
          </span>
          <span className="pl-2">
            <SecondaryGrayButton
              label="Retrain"
              onClick={() => {
                console.log("retrain clicked");
              }}
              prefixIcon={<IconRefresh className="w-4 h-4" />}
            />
          </span>
          <div className="flex ml-auto">
            <input
              type="text"
              //value={trainingUrl}
              //onChange={(e) => setTrainingUrl(e.target.value)}
              placeholder="Search"
              className="w-full p-2 border-[1px] rounded-md text-sm"
            />
          </div>
        </div>
        <SortableTable
          columns={columns}
          tableData={scrapedUrls}
          setTableData={setScrapedUrls}
        />
      </div> */}
    </div>
  );
}
