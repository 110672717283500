import React from "react";
import { Outlet, Route } from "react-router-dom";
import AppLoading from "../components/AppLoading";
import useAuth from "../hooks/useAuth";
import ToastAlertProvider from "@/providers/ToastAlertProvider";
import MainLayout from "@/pages/Shared/MainLayout";
import { AIConfigsProvider } from "@/providers/AIConfigsProvider";
import { AutomationsProvider } from "@/providers/AutomationsProvider";
import { RoleListItemsProvider } from "@/providers/RoleListItemsProvider";
import { RulesProvider } from "@/providers/RulesProvider";
import { SubscriptionsProvider } from "@/providers/SubscriptionsProvider";
import { SelectedTeamProvider } from "@/providers/SelectedTeamProvider";
import LanguageConfigsProvider from "@/pages/Messaging/WebMessenger/providers/LanguageConfigProvider";
import StyleConfigsProvider from "@/pages/Messaging/WebMessenger/providers/StyleConfigProvider";
import { SkillListItemsProvider } from "@/providers/SkillsProvider";
import { TeamsProvider } from "@/providers/TeamsProvider";
import { UsersProvider } from "@/providers/UsersProvider";
import ProfileProvider from "@/providers/ProfileProvider";
import StatusProvider from "@/providers/StatusProvider";
import { SiteProvider } from "@/providers/SiteProvider";
import { WorkflowsProvider } from "@/providers/WorkflowsProvider";

export function PrivateRoute(props: any) {
  const { component, ...rest } = props;
  const auth = useAuth();

  if (auth.isAuthenticated()) {
    return (
      <SiteProvider>
        <TeamsProvider>
          <ProfileProvider>
            <StatusProvider>
              <UsersProvider>
                <SkillListItemsProvider>
                  <SelectedTeamProvider>
                    <RoleListItemsProvider>
                      <ToastAlertProvider>
                        <RulesProvider>
                          <AutomationsProvider>
                            <WorkflowsProvider>
                              <AIConfigsProvider>
                                <SubscriptionsProvider>
                                  <LanguageConfigsProvider>
                                    <StyleConfigsProvider>
                                      <MainLayout>
                                        <Outlet />
                                      </MainLayout>
                                    </StyleConfigsProvider>
                                  </LanguageConfigsProvider>
                                </SubscriptionsProvider>
                              </AIConfigsProvider>
                            </WorkflowsProvider>
                          </AutomationsProvider>
                        </RulesProvider>
                      </ToastAlertProvider>
                    </RoleListItemsProvider>
                  </SelectedTeamProvider>
                </SkillListItemsProvider>
              </UsersProvider>
            </StatusProvider>
          </ProfileProvider>
        </TeamsProvider>
      </SiteProvider>
    );
  } else {
    auth.loginRedirect();
    return <AppLoading />;
  }
}
