import React, { act, useEffect, useState } from "react";
import CustomizableSelect from "@velaro/velaro-shared/src/components/inputs/CustomizableSelect";
import Toggle from "@velaro/velaro-shared/src/components/Toggle";
import AIChatbotSettingsRow from "./AIChatbotSettingsRow";
import AIChatbotSettingsRowToggle from "./AIChatbotSettingsRowToggle";
import AIChatbotSettingsTabs from "./AIChatbotSettingsTabs";
import { useParams } from "react-router-dom";
import useAIConfigs from "@/hooks/useAIConfigs";
import LoadingSpinner from "@velaro/velaro-shared/src/components/LoadingSpinner";
import { AIConfiguration } from "../types";

export default function AIChatbotSettingsGeneral() {
  const { id } = useParams();
  const { aiConfigurations, loading, update } = useAIConfigs();
  const [config, setConfig] = useState<AIConfiguration>();
  const [configIndex, setConfigIndex] = React.useState<number>();

  useEffect(() => {
    if (aiConfigurations.length > 0 && id) {
      const configIndex = aiConfigurations.findIndex(
        (config) => config.id === parseInt(id)
      );
      if (configIndex !== null && configIndex !== undefined) {
        setConfig(aiConfigurations[configIndex]);
        setConfigIndex(configIndex);
      }
    }
  }, [aiConfigurations, id]);

  const handleModel = (mod: string) => {
    if (!config || configIndex == null || configIndex == undefined) return;
    config.aiModel = mod;
    update({ ...config }, configIndex);
  };
  const handleLanguage = (lang: string) => {
    if (!config || configIndex == null || configIndex == undefined) return;
    config.language = lang;
    update({ ...config }, configIndex);
  };
  const handleReplyLength = (leng: string) => {
    if (!config || configIndex == null || configIndex == undefined) return;
    config.replyLength = leng;
    update({ ...config }, configIndex);
  };
  const handleUsageLimit = (limit: number) => {
    if (!config || configIndex == null || configIndex == undefined) return;
    config.usageLimit = limit;
    update({ ...config }, configIndex);
  };
  const handleVectorSearchMethod = (method: string) => {
    if (!config || configIndex == null || configIndex == undefined) return;
    config.vectorSearchMethod = method;
    update({ ...config }, configIndex);
  };
  const handleKnowledgeRestriction = () => {
    if (!config || configIndex == null || configIndex == undefined) return;
    config.knowledgeRestriction = !config.knowledgeRestriction;
    update({ ...config }, configIndex);
  };
  const handleFollowUp = () => {
    if (!config || configIndex == null || configIndex == undefined) return;
    config.followUp = !config.followUp;
    update({ ...config }, configIndex);
  };
  const handleRateResponse = () => {
    if (!config || configIndex == null || configIndex == undefined) return;
    config.rateResponse = !config.rateResponse;
    update({ ...config }, configIndex);
  };
  const handleActivityCheck = () => {
    if (!config || configIndex == null || configIndex == undefined) return;
    config.activityCheck = !config.activityCheck;
    update({ ...config }, configIndex);
  };
  const handleTemperature = (temperature: number) => {
    if (!config || configIndex == null || configIndex == undefined) return;
    config.temperature = temperature;
    update({ ...config }, configIndex);
  };

  //Todo translate
  const models = [
    {
      label: "OpenAI",
      sublabel: "gpt-3.5-turbo",
      action: () => handleModel("gpt-3.5-turbo")
    },
    {
      label: "OpenAI",
      sublabel: "gpt-4-turbo",
      action: () => handleModel("gpt-4-turbo")
    },
    {
      label: "Anthropic",
      sublabel: "claude-3-haiku-20240307",
      action: () => handleModel("claude-3-haiku-20240307")
    },
    {
      label: "Google",
      sublabel: "Gemini 1.5",
      action: () => handleModel("Gemini 1.5")
    },
    {
      label: "Meta",
      sublabel: "Llama 2",
      action: () => handleModel("Llama 2")
    }
  ];

  const languages = [
    {
      label: "Auto Detect",
      action: () => handleLanguage("Auto Detect")
    },
    {
      label: "English",
      action: () => handleLanguage("English")
    },
    {
      label: "Spanish",
      action: () => handleLanguage("Spanish")
    }
  ];

  const replyLength = [
    {
      label: "Short",
      sublabel: "(25 Words)",
      action: () => handleReplyLength("Short")
    },
    {
      label: "Medium",
      sublabel: "(50 Words)",
      action: () => handleReplyLength("Medium")
    },
    {
      label: "Long",
      sublabel: "(100 Words)",
      action: () => handleReplyLength("Long")
    }
  ];

  const vectorSearchMethods = [
    {
      label: "None",
      sublabel: "No vector search method",
      action: () => handleVectorSearchMethod("None")
    },
    {
      label: "Website",
      sublabel: "Search vectors from website",
      action: () => handleVectorSearchMethod("Website")
    },
    {
      label: "File",
      sublabel: "Search vectors from file",
      action: () => handleVectorSearchMethod("File")
    }
  ];

  const useageLimits = [
    {
      label: "25 Messages",
      sublabel: "(per hour)",
      action: () => handleUsageLimit(25)
    },
    {
      label: "50 Messages",
      sublabel: "(per hour)",
      action: () => handleUsageLimit(50)
    },
    {
      label: "100 Messages",
      sublabel: "(per hour)",
      action: () => handleUsageLimit(100)
    }
  ];

  if (loading || !config) return <LoadingSpinner />;

  return (
    <div className="flex flex-col">
      <AIChatbotSettingsTabs selectedTab="General" aiConfigId={id!} />
      <div className="bg-white p-6 border-[1px] rounded-2xl shadow-sm w-[750px] self-center">
        <div className="border-b-[1px]">
          <div className="font-semibold text-lg pb-1">Chatbot Settings</div>
          <div className="text-slate-500 text-sm pb-2">
            Configure your chatbot easily with customizable settings.
          </div>
        </div>
        <AIChatbotSettingsRow
          title="Internal Chatbot Name"
          subtitle="A unique identifier for organizing and managing your chatbots within
            the app. Not visible to your customers or visitors."
          input={
            <input
              value={config?.name || ""}
              onChange={(e) => {
                if (!config || configIndex == null || configIndex == undefined)
                  return;
                config.name = e.target.value;
                update({ ...config }, configIndex);
              }}
              type="text"
              placeholder="Enter a name"
              className="w-full p-2 border-[1px] rounded-md text-sm"
            />
          }
        />

        <AIChatbotSettingsRow
          title="Instructions"
          subtitle="Define how the AI chatbot interacts with users. It sets the tone,
          language and the behavior of the AI."
          input={
            <textarea
              value={config?.prompt || ""}
              onChange={(e) => {
                if (!config || configIndex == null || configIndex == undefined)
                  return;
                config.prompt = e.target.value;
                update({ ...config }, configIndex);
              }}
              placeholder="You are a helpful customer support agent. Your primary job is to provide accurate and helpful answers to questions from the user you are interacting with. You're skilled at handling multi-turn conversations and you're capable of asking clarifying questions if the user's query is unclear."
              className="w-full p-2 border-[1px] rounded-md text-sm"
              rows={5}
            />
          }
        />

        <AIChatbotSettingsRow
          title="Tone"
          subtitle="A unique identifier for organizing and managing your chatbots within
            the app. Not visible to your customers or visitors."
          input={
            <input
              value={config?.tone || ""}
              onChange={(e) => {
                if (!config || configIndex == null || configIndex == undefined)
                  return;
                config.tone = e.target.value;
                update({ ...config }, configIndex);
              }}
              type="text"
              placeholder="Be polite and friendly. Don’t use slang and emojis."
              className="w-full p-2 border-[1px] rounded-md text-sm"
            />
          }
        />

        {/* <div className="py-3">
          <div className="flex">
            <div className="font-semibold text-sm pb-1 flex ">AI Model</div>
            <div className="font-semibold text-sm pb-1 flex ml-auto text-cornflower-blue-400 underline">
              Upgrade Plan
            </div>
          </div>
          <div className="text-slate-500 text-sm pb-2">
            The AI model will be used to generate answers for your chatbot.
          </div>
          <div className="text-sm">
            <CustomizableSelect options={models} defaultOption={models[0]} />
          </div>
        </div> */}

        <div className="py-3">
          <div className="flex">
            <div className="flex flex-col w-1/2 pr-3">
              <div className="font-semibold text-sm pb-1 flex flex-row ">
                Language
              </div>
              <div className="text-sm pb-1 flex flex-row ">
                <CustomizableSelect
                  options={languages}
                  defaultOption={
                    languages.filter((x) => x.label == config.language)[0] ||
                    languages[0]
                  }
                />
              </div>
            </div>
            <div className="flex flex-col w-1/2">
              <div className="font-semibold text-sm pb-1 flex flex-row ">
                Reply Length
              </div>
              <div className="text-sm pb-1 flex flex-row ">
                <CustomizableSelect
                  options={replyLength}
                  defaultOption={
                    replyLength.filter(
                      (x) => x.label == config.replyLength
                    )[0] || replyLength[0]
                  }
                />
              </div>
            </div>
          </div>
        </div>

        {/* <AIChatbotSettingsRowToggle
          title="Knowledge Restriction"
          subtitle="Limit your chatbot’s response scope to ensure it sticks to relevant information"
          input={
            <Toggle
              toggled={config?.knowledgeRestriction}
              changeToggle={() => handleKnowledgeRestriction()}
            />
          }
        />

        <AIChatbotSettingsRowToggle
          title="Follow Up Questions"
          subtitle="Turn on to generate follow-up questions based on the ongoing conversation and the training data"
          input={
            <Toggle
              toggled={config?.followUp}
              changeToggle={() => handleFollowUp()}
            />
          }
        />

        <AIChatbotSettingsRowToggle
          title="Rate Chatbot Response"
          subtitle="Turn on to enable users to rate chatbot interactions with thumbs up or thumbs down, aiding in performance improvement"
          input={
            <Toggle
              toggled={config?.rateResponse}
              changeToggle={() => handleRateResponse()}
            />
          }
        />

        <AIChatbotSettingsRowToggle
          title="Activity Check"
          subtitle="Turn on to monitor inactivity and close inactive chat sessions to maintain efficiency"
          input={
            <Toggle
              toggled={config?.activityCheck}
              changeToggle={() => handleActivityCheck()}
            />
          }
        />

        <div className="py-3">
          <div className="flex">
            <div className="flex flex-col w-1/2 pr-3">
              <div className="font-semibold text-sm pb-1 flex flex-row ">
                Usage Limit
              </div>
              <div className="text-sm pb-1 flex flex-row ">
                <CustomizableSelect
                  options={useageLimits}
                  defaultOption={useageLimits[0]}
                />
              </div>
            </div>
            <div className="flex flex-col w-1/2">
              <div className="font-semibold text-sm pb-1 flex flex-row ">
                Usage Limit Warning
              </div>
              <div className="text-sm pb-1 flex flex-row ">
                <input
                  type="text"
                  placeholder="You've reached the message limit"
                  className="w-full p-2 border-[1px] rounded-md text-sm"
                />
              </div>
            </div>
          </div>
        </div> */}

        <AIChatbotSettingsRow
          title="Temperature"
          subtitle="Gets or sets what sampling temperature to use, between 0 and 2. Values above 1 will make the output more random, while lower values like 0.2 will make it more focused and deterministic (default: 1)."
          input={
            <input
              value={config?.temperature || ""}
              onChange={(e) => {
                if (!config || configIndex == null || configIndex == undefined)
                  return;
                config.temperature = e.target.valueAsNumber;
                update({ ...config }, configIndex);
              }}
              type="number"
              min="0"
              max="2"
              placeholder="1"
              className="w-full p-2 border-[1px] rounded-md text-sm"
            />
          }
        />

        <AIChatbotSettingsRow
          title="Deployment Name"
          subtitle="A Unique Azure Deployment. Consult Velaro Customer Support to have this modified."
          input={
            <input
              value={config?.deploymentName || ""}
              onChange={(e) => {
                if (!config || configIndex == null || configIndex == undefined)
                  return;
                config.deploymentName = e.target.value;
                update({ ...config }, configIndex);
              }}
              type="text"
              placeholder={config?.deploymentName || ""}
              className="w-full p-2 border-[1px] rounded-md text-sm text-slate-500"
            />
          }
        />

        <AIChatbotSettingsRow
          title="Index"
          subtitle="A Unique Azure Index required for using File Vector Search. Consult Velaro Customer Support to have this modified."
          input={
            <input
              value={config?.indexName || ""}
              onChange={(e) => {
                if (!config || configIndex == null || configIndex == undefined)
                  return;
                config.indexName = e.target.value;
                update({ ...config }, configIndex);
              }}
              type="text"
              placeholder={config?.indexName || ""}
              className="w-full p-2 border-[1px] rounded-md text-sm text-slate-500"
            />
          }
        />

        {/* {config.deploymentName && (
          <AIChatbotSettingsRow
            title="Deployment Name"
            subtitle="A Unique Azure Deployment. Consult Velaro Customer Support to have this modified."
            input={
              <input
                value={config?.deploymentName || ""}
                type="text"
                placeholder={config?.deploymentName || ""}
                className="w-full p-2 border-[1px] rounded-md text-sm text-slate-500"
                disabled
              />
            }
          />
        )}
        {config.indexName && (
          <AIChatbotSettingsRow
            title="Index"
            subtitle="A Unique Azure Index required for using File Vector Search. Consult Velaro Customer Support to have this modified."
            input={
              <input
                value={config?.indexName || ""}
                type="text"
                placeholder={config?.indexName || ""}
                className="w-full p-2 border-[1px] rounded-md text-sm text-slate-500"
                disabled
              />
            }
          />
        )} */}

        {/* <div className="flex flex-col w-1/2 pt-4">
          <div className="font-semibold text-sm pb-1 flex flex-row ">
            Train Data Method
          </div>
          <div className="text-sm pb-1 flex flex-row ">
            <CustomizableSelect
              options={vectorSearchMethods}
              defaultOption={
                vectorSearchMethods.filter(
                  (x) => x.label == config.vectorSearchMethod
                )[0]
              }
            />
          </div>
        </div> */}
      </div>
    </div>
  );
}
