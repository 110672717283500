import Modal from "@velaro/velaro-shared/src/components/Modal";
import React, { useState } from "react";
import RuleFormFactory from "./ValueForms/RuleFormFactory";
import FormGroup from "@velaro/velaro-shared/src/components/FormGroup";
import useRules from "@/hooks/useRules";
import { UseAIValue } from "./ValueForms/AIRuleForm";
import { UseAutomationValue } from "./ValueForms/AutomationRuleForm";
import { Rule, RuleTrigger, triggerDefs } from "./types";
import { ExpressionEditor } from "@/components/Expressions/ExpressionEditor";
import { PrimaryBlueButton } from "@velaro/velaro-shared/src/components/Buttons/Buttons";
import Input from "@/components/Input";
import Select from "@/components/Select";
import { WorkflowOnResolveValue } from "./ValueForms/WorkflowOnResolveRuleForm";

interface Props {
  rule?: Rule;
  close(): void;
}

export default function RuleEditModal({ rule, close }: Props) {
  const [workingModel, setWorkingModel] = useState<Rule>(
    rule || {
      id: undefined,
      trigger: "use-automation",
      priority: 1,
      expression: [],
      value: { automation: "" }
    }
  );
  const { add, update, saving } = useRules();

  const newRule = !workingModel.id;
  const title = newRule ? "Create Rule" : "Edit Rule";

  return (
    <Modal maxWidth="max-w-5xl" show={true}>
      <>
        <Modal.Header title={title} onClose={close} />
        <Modal.Body>
          <div className="flex">
            <div className="w-1/2">
              <FormGroup label="Priority">
                <Input
                  type="number"
                  required
                  value={workingModel.priority}
                  onChange={(value) =>
                    setWorkingModel({
                      ...workingModel,
                      priority: parseInt(value)
                    })
                  }
                />
              </FormGroup>
              <FormGroup label="Trigger">
                <Select
                  options={triggerDefs}
                  value={workingModel.trigger}
                  onChange={(v) =>
                    setWorkingModel({
                      ...workingModel,
                      trigger: v,
                      value: defaultRuleValue(workingModel.trigger)
                    })
                  }
                />
              </FormGroup>
              <RuleFormFactory
                value={workingModel.value}
                updateValue={(v) =>
                  setWorkingModel({ ...workingModel, value: v })
                }
                trigger={workingModel.trigger}
              />
            </div>
            <div className="px-2 w-1/2 max-h-[500px] overflow-auto">
              <ExpressionEditor
                expressionType={workingModel.trigger}
                expression={workingModel.expression}
                onUpdate={(expression) =>
                  setWorkingModel({ ...workingModel, expression })
                }
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="flex p-4">
            <div className="ml-auto">
              <PrimaryBlueButton
                className="float-right"
                disabled={saving}
                label={saving ? "Saving..." : "Save"}
                onClick={() => {
                  close();
                  newRule ? add(workingModel) : update(workingModel);
                }}
              />
            </div>
          </div>
        </Modal.Footer>
      </>
    </Modal>
  );
}

function defaultRuleValue(trigger: RuleTrigger) {
  switch (trigger) {
    case "use-ai":
      return { aiConfig: "" } as UseAIValue;
    case "use-workflow-on-resolve":
      return { workflowId: 0 } as WorkflowOnResolveValue;
    case "use-automation":
    default:
      return { automation: "" } as UseAutomationValue;
  }
}
